import { LoadableAfterPaint } from '@confluence/loadable';

export const WhatsNewInConfluence = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-WhatsNewInConfluence" */ './WhatsNewInConfluence'))
			.WhatsNewInConfluenceErrorBoundary,
});

export const WhatsNewInConfluenceNav3 = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-WhatsNewInConfluenceNav3" */ './WhatsNewInConfluenceNav3'
			)
		).WhatsNewInConfluenceNav3,
});
